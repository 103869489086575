<button type="button" class="btn bg-transparent border-0 p-0" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="bi bi-three-dots bi-large"></i>
</button>
<ul class="dropdown-menu">

    <li *ngIf="withdrawAllowed() && hasEditRights"><a class="dropdown-item pt-1 pb-1"
            (click)="applyAction(requestStatusActionsEnum.Withdrawn)"
            translate="Request.Status.RequestOptions.WithdrawRequest"></a>
    </li>

    <li *ngIf="hasEditRights && downloadAllowed()"><a class="dropdown-item pt-1 pb-1"
            (click)="applyAction(requestStatusActionsEnum.Download)"
            translate="Request.Status.RequestOptions.DownloadRequest"></a></li>

    <li *ngIf="purgeAllowed() && hasEditRights"><a class="dropdown-item pt-1 pb-1"
            (click)="applyAction(requestStatusActionsEnum.Purge)" translate="Request.Status.RequestOptions.Purge"></a>
    </li>

    <li *ngIf="restoreAllowed() && hasEditRights"><a class="dropdown-item pt-1 pb-1"
            (click)="applyAction(requestStatusActionsEnum.Restore)"
            translate="Request.Status.RequestOptions.Restore"></a>
    </li>

    <li *ngIf="deleteAllowed() && hasEditRights"><a class="dropdown-item pt-1 pb-1"
            (click)="applyAction(requestStatusActionsEnum.Delete)" translate="Request.Status.RequestOptions.Delete"></a>
    </li>

    <li *ngIf="allowedToChangeOwnership"><a class="dropdown-item pt-1 pb-1"
            (click)="applyAction(requestStatusActionsEnum.BecomeOwner)"
            translate="Request.Status.RequestOptions.BecomeOwner"></a></li>

    <li *ngIf="hasEditRights"><a class="dropdown-item pt-1 pb-1" (click)="applyAction(requestStatusActionsEnum.Viewlog)"
            translate="Request.Status.RequestOptions.ViewLog"></a></li>
</ul>