import { Injectable, OnDestroy } from "@angular/core";
import { BaseAccessPropertiesRequestClass } from "../models/base-access-properties-request.class";
import { Subscription } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { User } from "../models/user";
import { ExistsIn } from "../_helpers/ArrayExtensionMethodHelper.functions";

/**
 * Service that can validate what actions the CURRENT USER is allowed to do on the given Request
 */
@Injectable({
    providedIn: 'root'
})
export class VerifyRequestActionService implements OnDestroy {

    private usersubscription: Subscription;
    private user!: User;


    constructor(private authenticationService: AuthenticationService
    ) {
        this.usersubscription = this.authenticationService.currentUser.subscribe((userModel) => {
            this.user = userModel;
        });
    }
    /**
     * is the given request+Action ALLOWED for the CURRENT USER
     * @param request 
     * @param forAction 
     * @returns 
     */
    public IsAllowed(request: BaseAccessPropertiesRequestClass, forAction: RequestActionTypeEnum): boolean {
        if (!this.user || !request) {
            return false;
        }
        let isOwner = this.isOwner(request.ownerGuid);
        let inWorkGroup = this.isInWorkgroup(request.workgroupGuid);

        let showAllDocsInOrg = sessionStorage.getItem("ShowAllDocsInOrg");
        let hasShowAllRequestForMyOrganisationEnabled = (showAllDocsInOrg ?? "false").toLowerCase() == "true";

        switch (forAction) {
            case RequestActionTypeEnum.View:
                return true;
            case RequestActionTypeEnum.Purge:
            case RequestActionTypeEnum.DELETE:
            case RequestActionTypeEnum.ChangeWorkGroup:
                return isOwner;
            case RequestActionTypeEnum.Withdraw:
            case RequestActionTypeEnum.EditDeadline:
            case RequestActionTypeEnum.SendReminder:
            case RequestActionTypeEnum.Restore:
            case RequestActionTypeEnum.Download:
            case RequestActionTypeEnum.Export:
            case RequestActionTypeEnum.EditSendGroup:
            case RequestActionTypeEnum.EditUserDetails:
            case RequestActionTypeEnum.TakeAPeekAtInvite:
                return isOwner || inWorkGroup;
            case RequestActionTypeEnum.BecomeOwner:
                return !isOwner && hasShowAllRequestForMyOrganisationEnabled && this.user.isOrganisationAdmin;
            default:
                return false;
        }
    }
    ngOnDestroy() {
        if (this.usersubscription) { this.usersubscription.unsubscribe(); }
    }


    public isOwner(ownerGuid: string): boolean {
        // optional:
        //let user = this.authenticationService.currentUserValue;
        return ownerGuid === this.user.sub;
    }

    public isInWorkgroup(workgroupId: string | null | undefined): boolean {
        if (!workgroupId) return false;
        let workgroups = this.authenticationService.workgroups;
        return ExistsIn(workgroups, "Guid", workgroupId);
    }


}


export enum RequestActionTypeEnum {
    /**
     * Set the request on the 'Expired' status
     */
    Purge = 1,
    /**
     * REMOVES the entire request from our platform
     */
    DELETE = 2,
    /**
     * Withdraw the request
     */
    Withdraw = 3,
    /**
     * 'Restore' the request from the Expired/Purged state
     */
    Restore = 4,
    /**
     * Download the request (NOT ACTOR ACTION)
     */
    Download = 5,
    /**
     * 'Download' the request to a cloud (NOT ACTOR ACTION)
     */
    Export = 6,
    /**
     * Become the 'Owner' of the request
     */
    BecomeOwner = 7,
    /**
     *  change the current deadline of a sendgroup
     */
    EditDeadline = 8,
    /**
     * Send a reminder email to a sendgroup/Actor
     */
    SendReminder = 9,
    /**
     * Edit the contact information of a Person
     */
    EditUserDetails = 10,
    /**
     * STATUS SCREEN
     */
    View = 11,
    /**
     * Edit the information of a Sendgroup
     */
    EditSendGroup = 12,
    /**
     * why wait, when you can take a peek right NOW!
     */
    TakeAPeekAtInvite = 13,
    /**
     * Change the workgroup of a request
     */
    ChangeWorkGroup = 14,
}