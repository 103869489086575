import { EditorActor } from "./requestEditor";

export class SendGroupModel {
    sendGroupGuid!: string;
    name!: string;
    sendDate!: Date;
    deadline!: Date;
    deadlineDays: number = 0;
    order!: number;
    actions!: EditorActor[];
}

export class UpdateSendGroupDeadlineDaysModel{
    sendGroupGuid!: string;
    deadlineDays!: number;

}

export class UpdateSendGroupDeadlineModel{
    sendGroupGuid!: string;
    deadline!: Date;

}

export class UpdateSendGroupModel{
    sendGroupGuid!: string;
    sendGroupName!: string
    deadline?: Date | null;
    deadlineDays?: number | null;
}

export class UpdateSendGroupNameModel{
    sendGroupGuid!: string;
    sendGroupName!: string
}
