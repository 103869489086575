import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StringHelper } from 'src/app/directives/string-helper';
import { MinimumWordCountValidator } from 'src/app/directives/minimum-word-count-validator.directive';
import { DossierService } from 'src/app/services/dossier.service';
import { Observable, catchError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from 'src/app/services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/services/toast.service';
import { WithdrawRequestsRequestModel } from './withdraw-requests-request.model';

export const WithdrawRequestModalOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  size: 'md'
};

@Component({
  templateUrl: './withdraw-request.modal.html'
})
export class WithdrawRequestModal {

  @Output() itemsWithdrawn = new EventEmitter();
  @Input() selectedRequestIds: string[] = [];

  MIN_NEEDED_LENGTH = 5;
  MAX_ALLOWED_LENGTH = 500;
  MIN_AMOUNT_OF_WORDS = 2;

  withdrawReasonControl: FormControl;
  showNotDescriptiveTooltip: boolean = false;
  stringHelper = new StringHelper();
  translations = <any>{};

  get isFormValid(): boolean {
    return this.withdrawReasonControl.valid;
  }

  constructor(private activeModal: NgbActiveModal, private dossierService: DossierService, private loggingService: LoggingService, private translateService: TranslateService, private toastService: ToastService) {
    this.withdrawReasonControl = new FormControl('',
      [
        Validators.required,
        Validators.minLength(this.MIN_NEEDED_LENGTH),
        Validators.maxLength(this.MAX_ALLOWED_LENGTH),
        MinimumWordCountValidator(this.MIN_AMOUNT_OF_WORDS),
      ]);
    this.getTranslations();

  }

  public closeModal() {
    this.activeModal.close();
  }

  public verifyAndTriggerWithdraw() {
    if (!this.withdrawReasonControl.valid) {
      return;
    }
    let withdrawnReasonWithBrs = this.withdrawReasonControl.value;
    this.WithDrawRequests(withdrawnReasonWithBrs);
  }

  private WithDrawRequests(withdrawnReasonWithBrs: string) {
    let requestModel = new WithdrawRequestsRequestModel(this.selectedRequestIds, withdrawnReasonWithBrs);
    this.dossierService.withdrawRequests(requestModel)
      .pipe(catchError(this.handleError))
      .subscribe(() => {
        this.itemsWithdrawn.emit();
        this.closeModal();
      });
  }

  private getTranslations() {
    this.translateService.get([
      'Toasts.WithdrawError',
      'Toasts.Error',
    ]).subscribe(translation => {
      this.translations.toastsWithdrawError = translation['Toasts.WithdrawError'];
      this.translations.error = translation['Toasts.Error'];
    });
  }
  private handleError(error: HttpErrorResponse): Observable<never> {
    this.loggingService.logException(error);
    this.toastService.showError(this.translations.error, this.translations.toastsWithdrawError);
    this.closeModal();
    // Return an observable without an error, needed for the PIPE
    return new Observable<never>();
  }
}

