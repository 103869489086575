import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UpdateSendGroupDeadlineDaysModel, UpdateSendGroupDeadlineModel, UpdateSendGroupNameModel } from 'src/app/models/sendgroup.model';
import { AssignToSendGroupModel, ChangeActionsInPersonSendGroupModel, CreateSendGroupModel } from 'src/app/models/api/create-sendgroup.model';

@Injectable({
    providedIn: 'root'
})
export class BuilderSendgroupApiClient {

    private ControllerEndpoint(requestGuid: string) {
        return "/fe-api/builder-sendgroup/" + requestGuid;
    }

    constructor(private httpClient: HttpClient) { }

    createSendgroup(requestGuid: string, order: number, ForPersonGuids: string[]) {
        let model: CreateSendGroupModel = {
            Order: order,
            ForPersonGuids: ForPersonGuids
        };
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/create", model);
    }

    assignSendgroup(requestGuid: string, sendGroupGuid: string, ForPersonGuids: string[]) {
        let model: AssignToSendGroupModel = {
            SendGroupGuid: sendGroupGuid,
            ForPersonGuids: ForPersonGuids
        };
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/assign", model);
    }

    changeActorsByPersonOrder(requestGuid: string, personGuid: string, forInviteGuidsAndHisOrder: ChangeActionsInPersonSendGroupModel[]) {
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/" + personGuid + "/changeOrderForPersonInSendGroup", forInviteGuidsAndHisOrder);

    }

    mergeSendgroups(requestGuid: string) {
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/mergeSendgroups", {});
    }


    updateSendGroupDeadlineDays(requestGuid: string, updateSendGroupDeadlineDaysModel: UpdateSendGroupDeadlineDaysModel) {
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/deadline-days/update", updateSendGroupDeadlineDaysModel);
    }

    updateSendGroupDeadline(requestGuid: string, updateSendGroupDeadlineModel: UpdateSendGroupDeadlineModel) {
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/deadline/update", updateSendGroupDeadlineModel);
    }

    updateSendGroupName(requestGuid: string, updateSendGroupModel: UpdateSendGroupNameModel) {
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/update", updateSendGroupModel);
    }

}


