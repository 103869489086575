import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UpdateSendGroupDeadlineDaysModel, UpdateSendGroupDeadlineModel, UpdateSendGroupNameModel } from 'src/app/models/sendgroup.model';
import { SendGroupResendInviteModel } from 'src/app/models/api/actor-resend-invite.model';

@Injectable({
    providedIn: 'root'
})

export class StatusSendgroupApiClient {

    private ControllerEndpoint(requestGuid: string) {
        return "/fe-api/status-sendgroup/" + requestGuid;
    }

    constructor(private httpClient: HttpClient) { }

    updateSendGroupDeadlineDays(requestGuid: string, updateSendGroupDeadlineDaysModel: UpdateSendGroupDeadlineDaysModel) {
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/deadline-days/update", updateSendGroupDeadlineDaysModel);
    }

    updateSendGroupDeadline(requestGuid: string, updateSendGroupDeadlineModel: UpdateSendGroupDeadlineModel) {
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/deadline/update", updateSendGroupDeadlineModel);
    }

    updateSendGroupName(requestGuid: string, updateSendGroupModel: UpdateSendGroupNameModel) {
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/update", updateSendGroupModel);
    }


    resendSendGroupInvites(forRequestGuid: string, forSendGroupId: string) {
        var body: SendGroupResendInviteModel = {
            requestGuid: forRequestGuid,
            sendGroupGuid: forSendGroupId
        };
        // THIS IS NOT THE GENERIC ACTORS endpoint:
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(forRequestGuid) + "/resend-invite", body);
    }

}


