import { Injectable, OnDestroy } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { RequestApiClient } from '../../services/request.apiclient';
import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { Plugin } from 'src/app/models/plugin';
import { ModalOpenerService } from '../../modals/modal-opener.service';
import CloudItem from '../../models/clouditem';
import { CloudLocationsService } from '../../services/cloudlocations.service';
import { LoggingService } from '../../services/logging.service';
import { RequestStatuses } from '../../constants/status.enum';
import { StatusRequest } from '../../models/requests';
import { DossierService } from '../../services/dossier.service';
import { User } from 'src/app/models/user';
import { OrganisationPlugins } from 'src/app/constants/settings.enum';
import { EventLogApiClient, EventLogModel } from 'src/app/services/api-clients/event-log.api-client';
import { StringHelper } from 'src/app/directives/string-helper';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PlatformHubService } from 'src/app/services/platformhub.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { OrganisationApiClient } from 'src/app/services/organisation.apiclient';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UpdateExistingActorModel, UpdateExistingRequestActorModel } from 'src/app/models/api/update-existing-actor.model';
import { RequestStatusUpdateDeadlineDateModal } from '../../modals/request-status-update-deadline-date.modal/request-status-update-deadline-date.modal';
import { RequestStatusUpdateDeadlineDaysModal } from '../../modals/request-status-update-deadline-days.modal/request-status-update-deadline-days.modal';
import { RequestStatusPersonDialogModal } from './requeststatuspersondialog/request-status-person-dialog.modal';
import { ActorSendGroupStatusInformationModel, ActorStatusInformationModel } from 'src/app/models/api/actor-status-information.model';
import { ActorApiClient } from 'src/app/services/api-clients/actor.api-client';
import { DocumentTypeEnum, XbrlDocumentTypes } from 'src/app/constants/document-type.enum';
import { DocumentModel, PreloadedPagesByDocument } from 'src/app/models/document.model';
import { PdfPreparationDialog } from '../requestbuilder/pdf-preparation-dialog/pdf-preparation-dialog.component';
import { DocumentApiClient } from 'src/app/services/document.apiclient';
import { DomSanitizer } from '@angular/platform-browser';
import { PagesApiClient } from 'src/app/services/api-clients/pages.api-client';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { TheNaviGator } from 'src/app/services/the-navi-gator.service';
import { UpdateSendGroupModel, UpdateSendGroupDeadlineDaysModel, UpdateSendGroupDeadlineModel, UpdateSendGroupNameModel } from 'src/app/models/sendgroup.model';
import { StatusSendgroupApiClient } from 'src/app/services/api-clients/status-sendgroup.api-client';
import { EmailPreviewDialogComponent, EmailPreviewModalOptions } from '../requestbuilder/email-preview-dialog/email-preview-dialog.component';
import { PersonApiClient } from 'src/app/services/api-clients/person.api-client';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class RequestStatusService implements OnDestroy {
  plugins: Plugin[] = [];
  translations = <any>{};
  request: StatusRequest = {} as StatusRequest;
  actors: ActorSendGroupStatusInformationModel[] = [];
  actor: ActorStatusInformationModel = new ActorStatusInformationModel;
  dossierPersonId!: string;
  hasEditRights: boolean = false;
  user!: User;
  usersubscription: Subscription;
  requestSignalRSubscription: Subscription;
  actorSubscription: Subscription | null = null;
  eventLogSubscription: Subscription | null = null;
  stringHelper = new StringHelper();
  neededPlugins: any[] = [OrganisationPlugins.SharePoint, OrganisationPlugins.OneDrive, OrganisationPlugins.Teams];
  showMSplugins: boolean = false;
  allowedToChangeOwnership: boolean = false;
  eventlog!: EventLogModel[];
  /**
   * Opened/Collapsed sendgroups, so it can be retained on refresh
   */
  collapsedSendgroups: string[] = [];

  public actorId$: BehaviorSubject<string> = new BehaviorSubject("");
  public actors$: BehaviorSubject<ActorSendGroupStatusInformationModel[]> = new BehaviorSubject({} as ActorSendGroupStatusInformationModel[]);
  public selectedRequestId$: BehaviorSubject<string> = new BehaviorSubject("");
  public selectedRequest$: BehaviorSubject<StatusRequest> = new BehaviorSubject({} as StatusRequest);
  public eventLog$: BehaviorSubject<EventLogModel[]> = new BehaviorSubject({} as EventLogModel[]);

  requestId!: string;
  actorId!: string;
  isMobile: boolean = false;
  private _preloadedPagesByDocument: PreloadedPagesByDocument[] = [];

  constructor(
    private clipboardService: ClipboardService,
    private cloudLocationsService: CloudLocationsService,
    private dossierService: DossierService,
    private loggingService: LoggingService,
    private modalOpenerService: ModalOpenerService,
    private requestService: RequestApiClient,
    private toastService: ToastService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private platformHub: PlatformHubService,
    private eventLogApiClient: EventLogApiClient,
    private organisationApiClient: OrganisationApiClient,
    private modalService: NgbModal,
    private actorApiClient: ActorApiClient,
    private personApiClient: PersonApiClient,
    private documentService: DocumentApiClient,
    private sanitizer: DomSanitizer,
    private pageService: PagesApiClient,
    private platformHubService: PlatformHubService,
    private sendgroupService: StatusSendgroupApiClient,
    private theNaviGator: TheNaviGator
  ) {
    this.usersubscription = this.authenticationService.currentUser.subscribe((data) => {
      this.user = data;
    });
    this.requestSignalRSubscription = this.platformHub.statusRequest$.subscribe((request: StatusRequest) => {
      if (request && request.id === this.requestId) {
        if (request.workgroup && !request.workgroupName) {
          let workgroup = this.authenticationService.workgroups.find(workgroup => workgroup.Guid === request.workgroup);
          if (workgroup != null) request.workgroupName = workgroup.Name;
        }
        this.request = request;
        this.selectedRequest$.next(this.request);
        this.hasEditRights = this.request?.documentOwner?.userId === this.authenticationService.currentUserValue.sub || this.authenticationService.workgroups.some(w => w.Guid === this.request.workgroup);
        this.allowedToChangeOwnership = !this.hasEditRights && (this.user.isOrganisationAdmin || this.user.isWhitelabelAdmin || this.user.isSystemAdmin);
      }
    });

    this.isMobile = /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/.exec(navigator.userAgent) !== null;
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
  }
  ngOnDestroy() {
    if (this.usersubscription) this.usersubscription.unsubscribe();
    if (this.actorSubscription) this.actorSubscription.unsubscribe();
    if (this.eventLogSubscription) this.eventLogSubscription.unsubscribe();
    if (this.requestSignalRSubscription) this.requestSignalRSubscription.unsubscribe();
    if (this.requestId) {
      this.platformHubService.StopListeningToEventLog(this.requestId);
      this.platformHubService.StopListeningToActorList(this.requestId);
    }
  }
  public showingMSPlugins() {
    return this.showMSplugins;
  }

  public hasUserEditRights() {
    if (Object.keys(this.request).length === 0) {
      return false;
    }
    return (this.request?.documentOwner?.userId === this.authenticationService.currentUserValue.sub || this.authenticationService.workgroups.some(w => w.Guid === this.request.workgroup)) || (this.user.isOrganisationAdmin || this.user.isWhitelabelAdmin || this.user.isSystemAdmin);
  }

  public isUserAllowedToChangeOwnership() {
    return this.hasUserEditRights() && this.request?.documentOwner?.userId !== this.authenticationService.currentUserValue.sub && (this.user.isOrganisationAdmin || this.user.isWhitelabelAdmin || this.user.isSystemAdmin);
  }

  public openDashboard() {
    this.theNaviGator.Navigate_To_Dashboard();
  }

  /** Set the selected requestId to the given Info */
  public setSelectedRequestId(requestId: string) {
    // verify current with incoming:
    if (this.requestId == requestId) {
      // no change in Id -> no action
      return;
    }
    let previousRequestGuid = this.requestId;
    if (requestId === previousRequestGuid) return;
    this.requestId = requestId;

    if (requestId && requestId !== "") {
      this.getRequest();
      this.selectedRequestId$.next(requestId);
    }

    if (!this.requestId) {
      // we are resetting requestGuid;
      if (previousRequestGuid) {
        this.platformHubService.StopListeningToEventLog(previousRequestGuid);
        this.platformHubService.StopListeningToActorList(previousRequestGuid);
      }
      this.request = {} as StatusRequest;
      this.actors = [];
      this.eventlog = [];
      this.selectedRequest$.next(this.request);
      this.actors$.next(this.actors);
      this.eventLog$.next(this.eventlog);
      return;
    }
    if (previousRequestGuid == this.requestId) {
      // no changes
      return;
    }

    // we should stop listening to previous
    if (previousRequestGuid) {
      this.platformHubService.StopListeningToEventLog(previousRequestGuid);
      this.platformHubService.StopListeningToActorList(previousRequestGuid);
    }

    // now we are constantly listening on changing request, but we aren't unsubscribing.
    // we unsubscribe when the component is destroyed, this means it is to far apart.
    // listen/stop listening should both be orchestrated from the same place
    this.actorSubscription = this.platformHub.ListenToActorList(this.requestId).subscribe(newActorList => {
      this.actors = newActorList;
      this.actors$.next(this.actors);
    });
    this.eventLogSubscription = this.platformHubService.ListenToEventLog(this.requestId).subscribe(newEventLog => {
      this.eventlog = newEventLog;
      this.eventLog$.next(this.eventlog);
    });
  }

  setActors(actors: ActorSendGroupStatusInformationModel[]) {
    this.actors = actors;
    this.actors$.next(this.actors);
  }

  public downloadRequest(requestId: string) {
    this.toastService.showDefault(this.translations.DownloadTitle, this.translations.DownloadBody);

    this.requestService.downloadRequest(requestId)
      .then(value => {
        lastValueFrom(value)
          .then((data: any) => {
            let filename = data.headers.get("Content-Disposition").replace("attachment; filename=", "");
            this.saveFile(data.body, filename);
          })
          .catch(() => this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestsDownloadError + ""));
      })
      .catch(() => this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestsDownloadError + ""));
  }

  public exportRequest(requestId: string) {
    const modalRef = this.modalOpenerService.Open_CloudDocumentExport(this.plugins);
    modalRef.componentInstance.close.subscribe(async (event: CloudItem) => {
      if (event && event.folderId !== null && event.folderId !== undefined && event.parentId !== null && event.parentId !== undefined) {
        this.toastService.showDefault(this.translations.ExportTitle, this.translations.ExportBody);
        try {
          this.cloudLocationsService.saveParentExternalContent(event.provider, event.parentId, event.folderId, requestId).subscribe({
            next: () => {
            },
            error: () => {
              this.toastService.showError(this.translations.error, this.translations.toastsRequestsExportError);
            }
          })
        } catch (error: any) {
          this.toastService.showError(this.translations.error, this.translations.toastsRequestsExportError);
        }
        modalRef.close();
      } else {
        modalRef.close();
      }
    });
  }

  public purgeRequest(requestId: string) {
    this.requestService.purgeRequest(requestId)
      .then(value => {
        lastValueFrom(value)
          .then(() => this.toastService.showSuccess(this.translations.toastsPurged, this.translations.toastsRequestPurged))
          .catch(() => this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestsPurgeError + ""));
      })
      .catch(() => this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestsPurgeError + ""));
  }

  public deleteRequest(requestId: string) {
    this.requestService.deleteRequest(requestId)
      .then(value => {
        lastValueFrom(value)
          .then(() => {
            this.theNaviGator.Navigate_To_Dashboard();
          })
          .catch(() => this.toastService.showError(this.translations.error, this.translations.toastsRequestsDeleteError));
      })
      .catch(() => this.toastService.showError(this.translations.error, this.translations.toastsRequestsDeleteError));
  }

  public openWithdrawModal(request: StatusRequest) {
    let withdrawAllowedStatussen: string[] = RequestStatuses.ThatCanBeWithdrawn;

    if (request === undefined || !request) {
      return;
    }
    if (!withdrawAllowedStatussen.includes(request.status)) {
      this.toastService.showWarning(this.translations.error, this.translations.notAllowedWithdrawError);
      return;
    }

    this.modalOpenerService.Open_WithDrawnRequest([request.id]);
  }

  public openStatusViewLogModal(request: StatusRequest) {
    if (request === undefined || !request) {
      return;
    }
    this.modalOpenerService.Open_StatusViewLog(request);
  }


  public restoreRequest(request: StatusRequest) {
    this.requestService.restoreRequest(request.id, request.jobName).subscribe(() => {
      this.toastService.showSuccess(this.translations.toastsRestored, this.translations.toastsRequestRestored);
    });
  }

  public async becomeOwner(requestId: string) {
    let changeOwner = await this.dossierService.ChangeOwner(requestId);
    await lastValueFrom(changeOwner);

    this.toastService.showSuccess(this.translations.toastsChanged, this.translations.toastsRequestOwnerChanged);
  }

  public editPerson(actor: ActorStatusInformationModel) {
    if (actor === null) return;
    let ngbModalOptions: NgbModalOptions = {
      centered: true,
      size: 'md',
      backdrop: 'static'
    };
    const modalRef = this.modalService.open(RequestStatusPersonDialogModal, ngbModalOptions);
    modalRef.componentInstance.personToEdit = actor.editPersonModel;
    modalRef.componentInstance.actorGuid = actor.personGuid;
    modalRef.componentInstance.requestGuid = this.requestId;
    modalRef.closed.subscribe(
      (actorInformation: UpdateExistingActorModel) => {
        if (!actorInformation) {
          return;
        }
        let inviteId = actor.inviteId;
        let newEmail = actorInformation.email;
        let newMobile = actorInformation.mobile;
        let newLanguage = actorInformation.language;
        let newPersonalMessage = actorInformation.personalMessage;
        let personGuids = actorInformation.personGuids;
        this.actorApiClient.UpdateActorPerson(this.requestId, inviteId, newEmail, newMobile, newLanguage, newPersonalMessage, personGuids).subscribe((value) => {
          if (this.actor.isNextActor) {
            this.toastService.showSuccess(this.translations.success, this.translations.toastPPersonChangedWithInvite);
          } else {
            this.toastService.showSuccess(this.translations.success, this.translations.toastPersonChangedNoInvite);
          }
        },
          errorResponse => {
            if (errorResponse instanceof HttpErrorResponse) {
              // specific error for when you are trying to assign an existing user with a different name
              if (errorResponse.status == HttpStatusCode.Conflict) {
                this.toastService.showErrorByKey("Toasts.CannotAssignADifferentUser");
              }
              // specific error for when trying to assign a person already on request
              else if (errorResponse.status == HttpStatusCode.NotAcceptable) {
                // same message as below but we can make a difference if needed
                this.toastService.showErrorByKey("Toasts.ErrorDuringUserEditAlreadyAssignedToRequest");
              } else if (errorResponse.status == HttpStatusCode.BadRequest) {
                // same message as below but we can make a difference if needed
                this.toastService.showErrorByKey("Toasts.ErrorDuringUserEditRequestStatus");
              } else {
                // fallback
                this.toastService.showErrorByKey("Toasts.ErrorDuringUserEditRequestStatus");
              }
            }
          }
          ,);
      });
  }

  public openSendGroupChangeDeadlineModal(forSendGroup: UpdateSendGroupModel) {
    let ngbModalOptions: NgbModalOptions = {
      centered: true,
      size: 'md',
      backdrop: 'static'
    };
    const modalRef = this.modalService.open(RequestStatusUpdateDeadlineDateModal, ngbModalOptions);
    let deadlineToUse = forSendGroup.deadline!;
    var originalDeadlineAsDate = new Date(deadlineToUse)
    var deadlineAsDate = new Date(deadlineToUse)
    var useInitialAsMinDeadline = true;
    // is it in the past:  get future deadline
    if (deadlineAsDate <= new Date()) {
      deadlineAsDate = new Date();
      deadlineAsDate.setDate(deadlineAsDate.getDate() + 7); // THIS ADDS 7 DAYS
      deadlineAsDate.setMinutes(0); // Resets minutes
      deadlineAsDate.setHours(originalDeadlineAsDate.getHours()); // retains Hours
      useInitialAsMinDeadline = false;
    }
    (modalRef.componentInstance as RequestStatusUpdateDeadlineDateModal).deadline = deadlineAsDate;
    (modalRef.componentInstance as RequestStatusUpdateDeadlineDateModal).fullName = forSendGroup.sendGroupName!;
    (modalRef.componentInstance as RequestStatusUpdateDeadlineDateModal).useInitialAsMinDeadline = useInitialAsMinDeadline;
    (modalRef.componentInstance as RequestStatusUpdateDeadlineDateModal).newPickedDeadlineEventEmitter.subscribe(
      (newDeadline: Date) => {
        if (!newDeadline) return;
        forSendGroup.deadline = newDeadline;
        var updateDeadlineModel = new UpdateSendGroupDeadlineModel();
        updateDeadlineModel.deadline = forSendGroup.deadline
        updateDeadlineModel.sendGroupGuid = forSendGroup.sendGroupGuid
        this.sendgroupService.updateSendGroupDeadline(this.requestId, updateDeadlineModel).subscribe(
          {
            next: () => {
              modalRef.close();
              this.toastService.showSuccess(this.translations.success, this.translations.toastDeadlineChangedWithInvite);
            }, error: () => {
              modalRef.close();
              this.toastService.showErrorByKey("Toasts.ErrorDuringUserEditRequestStatus");
            }
          }
        );
      });
  }

  public openSendGroupChangeDeadlineDaysModal(forSendGroup: UpdateSendGroupModel) {
    let ngbModalOptions: NgbModalOptions = {
      centered: true,
      size: 'md',
      backdrop: 'static'
    };
    const modalRef = this.modalService.open(RequestStatusUpdateDeadlineDaysModal, ngbModalOptions);
    (modalRef.componentInstance as RequestStatusUpdateDeadlineDaysModal).deadlineDays = forSendGroup.deadlineDays!;
    (modalRef.componentInstance as RequestStatusUpdateDeadlineDaysModal).fullName = forSendGroup.sendGroupName;

    (modalRef.componentInstance as RequestStatusUpdateDeadlineDaysModal).newPickedDaysEventEmitter.subscribe(
      (newDeadlineDays: number) => {
        if (!newDeadlineDays) return;

        forSendGroup.deadlineDays = newDeadlineDays;
        var updateDeadlineDaysModel = new UpdateSendGroupDeadlineDaysModel();
        updateDeadlineDaysModel.deadlineDays = forSendGroup.deadlineDays
        updateDeadlineDaysModel.sendGroupGuid = forSendGroup.sendGroupGuid
        this.sendgroupService.updateSendGroupDeadlineDays(this.requestId, updateDeadlineDaysModel).subscribe(() => {
          this.toastService.showSuccess(this.translations.success, this.translations.toastDeadlineChangedNoInvite);
        });
      });
  }

  public openStatusEditSendgroupName(editSendgroup: UpdateSendGroupModel) {
    if (editSendgroup === undefined || !editSendgroup) {
      return;
    }
    this.modalOpenerService.Open_StatusEditSendgroupName(editSendgroup);
  }
  public async resendRequestInviteForActor(actor: ActorStatusInformationModel) {
    // TODO: BETER ERROR, THIS TALKS ABOUT REQUEST, WHILE WE ARE INVITING AN ACTOR
    (await this.actorApiClient.ResendInvite(this.requestId, actor.inviteId)).subscribe({
      error: () => this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestsResendError + ""),
      next: () => {
        let reminderSent = this.translateService.instant('Toasts.ReminderSent', { name: actor.fullName });
        this.toastService.showSuccess(this.translations.toastsSent, reminderSent);
      }
    },
    );
  }

  public async resendRequestInviteForSendGroup(sendGroup: ActorSendGroupStatusInformationModel) {
    // TODO: BETER ERROR, THIS TALKS ABOUT REQUEST, WHILE WE ARE INVITING AN ACTOR
    (await this.sendgroupService.resendSendGroupInvites(this.requestId, sendGroup.sendGroupGuid)).subscribe({
      error: () => this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestsResendError + ""),
      next: () => {
        let reminderSent = this.translateService.instant('Toasts.ReminderSent', { name: sendGroup.sendGroupName });
        this.toastService.showSuccess(this.translations.toastsSent, reminderSent);
      }
    },
    );
  }

  public async openPreview(document: any) {
    if (document && document.documentType == DocumentTypeEnum.RegularPdf) {
      this.getDocumentForOpeningPreview(document);
    } else if (document && (XbrlDocumentTypes.find(x => document.documentType == x) || document.documentType == DocumentTypeEnum.GenericTextFile)) {
      this.modalOpenerService.Open_ViewXblrOrTxt(this.request, document);
    } else if (document.documentType == DocumentTypeEnum.RegularXml || document.documentType == DocumentTypeEnum.DetachedSignature) {
      this.modalOpenerService.Open_ViewXml(this.request.id!, document);
    }
  }

  public getRightActor(actorId: string) {
    // TODO



    if (this.request.actors.findIndex(actor => actor.id === actorId) > -1) {
      // this.actor = this.request.actors[this.request.actors.findIndex(actor => actor.id === actorId)];
    } else {
      this.actor = new ActorStatusInformationModel;
    }
  }

  public setSelectedActorId(actorId: string) {
    if (this.actorId == actorId) {
      // NO CHANGE
      return;
    }
    this.actorId$.next(actorId);
    this.actorId = actorId;
    this.getActorsForRequestList();
  }


  //Private functions
  private getTranslations() {
    this.translateService.get([
      'Toasts.RequestsDownloadError',
      'Toasts.RequestsPurgeError',
      'Toasts.RequestsDeleteError',
      'Toasts.RequestsExportError',
      'Toasts.RequestOwnerChanged',
      'Toasts.Error',
      'Toasts.SingleNotAllowedWithdrawError',
      'Toasts.RequestPurged',
      'Toasts.RequestRestored',
      'Toasts.Purged',
      'Toasts.Changed',
      'Toasts.Restored',
      'Toasts.DownloadTitle',
      'Toasts.DownloadBody',
      'Toasts.ExportTitle',
      'Toasts.ExportBody',
      'Request.Status.AllDocuments',
      'Request.Status.Day',
      'Request.Status.Days',
      'Request.Status.Month',
      'Request.Status.Months',
      'Toasts.RequestsResendOne',
      'Toasts.RequestsResendError',
      'Toasts.Sent',
      'Toasts.Error',
      "Toasts.PersonChangedNoInvite",
      "Toasts.PersonChangedWithInvite",
      "Toasts.DeadlineChangedWithInvite",
      "Toasts.DeadlineChangedNoInvite",
      "Toasts.Success",
      "Toasts.ClipboardError",
      "Toasts.ClipboardNotSupported",
    ]).subscribe(translation => {
      this.translations.toastsRequestsDownloadError = translation['Toasts.RequestsDownloadError'];
      this.translations.toastsRequestsPurgeError = translation['Toasts.RequestsPurgeError'];
      this.translations.toastsRequestsDeleteError = translation['Toasts.RequestsDeleteError'];
      this.translations.toastsRequestsExportError = translation['Toasts.RequestsExportError'];
      this.translations.toastsRequestOwnerChanged = translation['Toasts.RequestOwnerChanged'];
      this.translations.error = translation['Toasts.Error'];
      this.translations.notAllowedWithdrawError = translation['Toasts.SingleNotAllowedWithdrawError'];
      this.translations.toastsRequestPurged = translation['Toasts.RequestPurged'];
      this.translations.toastsRequestRestored = translation['Toasts.RequestRestored'];
      this.translations.toastsPurged = translation['Toasts.Purged'];
      this.translations.toastsChanged = translation['Toasts.Changed'];
      this.translations.toastsRestored = translation['Toasts.Restored'];
      this.translations.DownloadTitle = translation['Toasts.DownloadTitle'];
      this.translations.DownloadBody = translation['Toasts.DownloadBody'];
      this.translations.ExportTitle = translation['Toasts.ExportTitle'];
      this.translations.ExportBody = translation['Toasts.ExportBody'];
      this.translations.AllDocuments = translation['Request.Status.AllDocuments'];
      this.translations.Day = translation['Request.Status.Day'];
      this.translations.Days = translation['Request.Status.Days'];
      this.translations.Month = translation['Request.Status.Month'];
      this.translations.Months = translation['Request.Status.Months'];
      this.translations.toastsRequestsResendOne = translation['Toasts.RequestsResendOne'];
      this.translations.toastsRequestsResendError = translation['Toasts.RequestsResendError'];
      this.translations.toastsSent = translation['Toasts.Sent'];
      this.translations.error = translation['Toasts.Error'];
      this.translations.success = translation['Toasts.Success'];
      this.translations.toastPersonChangedNoInvite = translation['Toasts.PersonChangedNoInvite'];
      this.translations.toastPPersonChangedWithInvite = translation['Toasts.PersonChangedWithInvite'];
      this.translations.toastDeadlineChangedWithInvite = translation['Toasts.DeadlineChangedWithInvite'];
      this.translations.toastDeadlineChangedNoInvite = translation['Toasts.DeadlineChangedNoInvite'];
      this.translations.toastsClipboardError = translation['Toasts.ClipboardError'];
      this.translations.toastsClipboardNotSupported = translation['Toasts.ClipboardNotSupported'];
    });
  }

  private getRequest() {
    this.requestService.getRequestForStatus(this.requestId).then(dossierResp => {
      lastValueFrom(dossierResp)
        .then((request: StatusRequest) => {
          this.request = request;
          this.getActorsForRequestList();
          this.getEventLog();
          this.getPlugins();
          this.hasEditRights = this.hasUserEditRights();
          this.allowedToChangeOwnership = this.isUserAllowedToChangeOwnership();
          this.selectedRequest$.next(this.request);
          this.requestService.extendDeadlinesIfNeeded(this.requestId).subscribe();
        }).catch((error) => {
          this.loggingService.logException(error);
          this.openDashboard();
        });
    });
  }

  private getEventLog() {
    this.eventLogApiClient.Get(this.requestId).subscribe({
      next: (events: any) => {
        this.eventlog = events;
        this.eventLog$.next(this.eventlog);
      },
      error: (error: HttpErrorResponse) => this.loggingService.logException(error)
    });
  }

  private getPlugins() {
    this.organisationApiClient.getEnabledPlugins(this.user.organisationGuid).subscribe({
      next: (data: any) => {
        this.plugins = data;
        if (this.plugins.filter(s => this.neededPlugins.includes(s.code)).length > 0) {
          this.showMSplugins = true;
        }
      }
    });
  }

  private getActorsForRequestList() {
    this.actorApiClient.GetForStatusOverview(this.requestId).subscribe((actors) => {
      this.setActors(actors);
    });
  }

  private getDocumentForOpeningPreview(doc: DocumentModel) {
    this.documentService.getDocument(this.request.id!, doc.id)
      .then(data => {
        lastValueFrom(data)
          .then((value: any) => {
            doc.id = value.id;
            doc.name = value.name;
            doc.fileSize = value.documentSize;
            doc.documentStatus = value.status;
            doc.pages = value.pages;
            this.request.documentInfo.filter((s: { id: string; }) => s.id === doc.id)[0].fileSize = doc.fileSize;
            this.request.documentInfo.filter((s: { id: string; }) => s.id === doc.id)[0].documentStatus = value.status;
            this.request.documentInfo.filter((s: { id: string; }) => s.id === doc.id)[0].pages = doc.pages;
            this.openPdfPreview(doc).catch((error) => this.loggingService.logException(error));
          })
          .catch((error) => this.loggingService.logException(error));
      })
      .catch((error) => this.loggingService.logException(error));
  }

  private saveFile(blobContent: Blob, fileName: string) {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }

  private async openPdfPreview(document: DocumentModel) {
    if (this.modalService.hasOpenModals()) return;
    let documentId: string = document.id;
    let documents = this.request.documentInfo;
    let doc = this.request.documentInfo.find(d => d.id === documentId);
    let ngbModalOptions: NgbModalOptions;
    if (!this.isMobile) {
      ngbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: false,
        modalDialogClass: 'pkiMd',
        windowClass: 'addSignatureFieldModal'
      };
    }
    else {
      ngbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: false,
        size: 'xl',
        windowClass: 'addSignatureFieldModalMobile'
      };
    }
    let modalRef: any = this.modalService.open(PdfPreparationDialog, ngbModalOptions);
    let modal: PdfPreparationDialog = modalRef.componentInstance;

    //TODO: refactor to proper inputs and events
    modal.document = doc!;
    modal.requestGuid = this.request.id;
    //modal.isMobile = this.isMobile!;
    modal.place = false;
    modal.displayfields = true;
    modal.setMoreSignaturefields = false;
    modal.showEditDocument = false;
    modal.getRequest.subscribe(() => this.request);
    if (!this.isMobile) {
      modal.zoomLevelChanged.subscribe(async (item: any) => {
        modalRef._windowCmptRef.instance.modalDialogClass = item.className;
        setTimeout(() => modal.afterZoomLevelChanged(), 100);
      });
    }
  }

  async copyToClipboard(Guid: string) {
    try {
      if (!navigator.clipboard) {
        this.toastService.showError(this.translations.error, this.translations.toastsClipboardNotSupported);
      }
      await this.clipboardService.Copy(Guid);
      this.toastService.showCopied();
    }
    catch (error: any) {
      this.toastService.showError(this.translations.error, this.translations.toastsClipboardError);
    }
  }

  public updateSendgroup(sendGroupUpdateModel: UpdateSendGroupModel) {
    var updateNameModel = new UpdateSendGroupNameModel();
    updateNameModel.sendGroupName = sendGroupUpdateModel.sendGroupName
    updateNameModel.sendGroupGuid = sendGroupUpdateModel.sendGroupGuid
    this.sendgroupService.updateSendGroupName(this.requestId, updateNameModel).subscribe({
      next: () => {
        this.toastService.showSaved();
      },
      error: () => { }
    });
  }

  async openEmailPreview(event: any) {
    let actor = this.request.actors.filter(item => item.id === event.inviteId && item.action)[0];
    if (actor && actor !== undefined) {
      (await this.actorApiClient.getEmailTemplate(this.requestId, actor.id)).subscribe({
        next: (value: any) => {
          const modalRef = this.modalService.open(EmailPreviewDialogComponent, EmailPreviewModalOptions);
          modalRef.componentInstance.emailTemplate = value;
        }
      });
    }
  }

  public async getRequestsForPerson(personGuid: string): Promise<UpdateExistingRequestActorModel[]> {
    // let call = await this.personApiClient.getRequestsForPerson(this.requestId, personGuid);
    let getRequests = await this.personApiClient.getRequestsForPerson(this.requestId, personGuid);
    let requests = await lastValueFrom(getRequests);
    return requests;
  }
}
